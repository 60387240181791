
import { login, sendCaptcha } from '@/api/index.js';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Toastrs from '@/utils/Toastrs';
import sondCode from '@/views/components/sondCode'

@Component
export default class MoDingtalk extends Vue {
  public form: any = {
    purpose: 'login',
    mobile: '',
    verification: '',
    auth_identity: 'empl',
  };
  public dunwNum: number = 60;

  public async sondCode() {
    sondCode({
      appid: (this as any).appid,
      type: "wxwork",
      mobile: this.form.mobile,
      number: this.dunwNum,
      setNum: (number: any) => {
        this.dunwNum = number;
      },
      setCode: (code: any) => {
        this.form.verification = code;
      },
    });
  }

  public async login() {
    const reg = /^1[3456789]\d{9}$/;
    if (!reg.test(this.form.mobile)) {
      Toastrs.error(`请输入正确的手机号码`);
      return;
    }

    if (this.form.verification.length != 4) {
      Toastrs.error(`请输入正确的验证码`);
      return;
    }
    const res: any = await login({
      ...this.form,
      source_appid: (this as any).appid,
      channels: 'wxwork',
    });
    if (res.code == 200 || res.code ==0) {
      (this as any).loginSuccess(res.data.utoken);
    }
  }
}
